<template>
  <div>
    <!-- Donate button -->

    <b-button
      v-b-modal.donates-modal
      variant="gradient-danger"
      block
    >
      <feather-icon
        icon="HeartIcon"
        class="mr-50"
      />
      <span class="align-middle">{{ $t('donate.support_project') }}</span>
    </b-button>

    <!-- Modal Window -->
    <b-modal
      id="donates-modal"
      ref="donates-modal"
      :title="$t('donate.secure_donation')"
      size="lg"
      centered
      cancel-variant="outline-secondary"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <b-container fluid>
          <b-button
            class="close text-primary"
            @click="hideModal"
          >
            X
          </b-button>
          <b-media
            no-body
          >
            <b-media-aside>
              <b-avatar
                rounded
                variant="success"
                size="24"
              >
                <feather-icon
                  icon="ShieldIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="align-self-center">
              <span>{{ $t('donate.secure_donation') }}</span>
            </b-media-body>
          </b-media>
        </b-container>
      </template>
      <!-- Modal Body -->
      <b-container fluid>
        <b-row class="d-flex justify-content-start align-items-center">
          <b-col
            sm="12"
            lg="8"
          >
            <b-form-group :label="$t('donate.donate_purpose')">
              <b-form-checkbox
                v-for="purpose in donateFeaturesOptions"
                :key="purpose.slug"
                v-model="form.description"
                :value="$t(`features.${purpose.slug}`)"
                name="flavour-3a"
                class="mb-1"
              >
                {{ $t(`features.${purpose.slug}`) }}<br>
                <span class="text-secondary">{{ $t(`features.${purpose.description}`) }}
                  <small class="text-nowrap"> ( {{ $t('donate.feature_development') }} ) </small>
                </span>
              </b-form-checkbox>
              <b-form-checkbox
                v-for="purpose in donateTeamOptions"
                :key="purpose.slug"
                v-model="form.description"
                :value="$t(`donate.${purpose.slug}`)"
                name="flavour-3a"
                class="mb-1"
              >
                {{ $t(`donate.${purpose.slug}`) }}<br>
                <span class="text-secondary">{{ $t(`donate.${purpose.description}`) }}🍺</span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="4"
          >
            <div class="text-center">
              <b-button-group class="pb-1 action">
                <b-button
                  v-ripple.400="'rgba(41, 128, 185, 0.15)'"
                  :variant="getDonateTypeButtonVariant('paydonate')"
                  @click="form.action='paydonate'"
                >
                  <span class="align-middle"> {{ $t('donate.give_once') }}</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(41, 128, 185, 0.15)'"
                  :variant="getDonateTypeButtonVariant('subscribe')"
                  @click="form.action='subscribe'"
                >
                  <feather-icon
                    icon="HeartIcon"
                    size="18"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('donate.monthly') }}</span>
                </b-button>
              </b-button-group>
            </div>
            <div class="amount-options pb-1">
              <div
                v-for="amountOption in amountOptions"
                :key="amountOption"
                class="amount-option"
              >
                <b-button
                  v-ripple.400="'rgba(41, 128, 185, 0.15)'"
                  block
                  :variant="getAmountButtonVariant(amountOption)"
                  @click="form.amount=amountOption"
                >
                  <span>{{ currencySymbol }} {{ amountOption }}</span>
                </b-button>
              </div>
            </div>
            <b-form>
              <b-input-group
                :prepend="currencySymbol"
                class="pb-1"
              >
                <cleave
                  id="amount"
                  v-model="amount"
                  class="form-control"
                  :raw="false"
                  :options="cleaveOptions.amount"
                />
                <b-input-group-append is-text>
                  <b-form-select
                    v-model="form.currency"
                    :options="currencyOptions"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
      <!-- Modal Footer -->
      <template #modal-footer>
        <b-container fluid>
          <b-row class="d-flex justify-content-start align-items-center">
            <b-col
              cols
              md="6"
            >
              <b-form-input
                id="comment"
                v-model="comment"
                type="text"
                :placeholder="$t('donate.enter_your_comment')"
              />
            </b-col>
            <b-col
              cols
              md="6"
              class="p-1"
            >
              <form
                ref="liqpayForm"
                method="POST"
                :action="formUrl"
                accept-charset="utf-8"
                @submit.prevent="submit"
              >
                <input
                  type="hidden"
                  name="data"
                  :value="liqpayFormData.data"
                >
                <input
                  type="hidden"
                  name="signature"
                  :value="liqpayFormData.signature"
                >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="lg"
                  type="submit"
                  block
                >
                  {{ $t('donate.donate') }}
                </b-button>
              </form>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BContainer,
  BButton,
  BButtonGroup,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BAvatar,
  BMedia,
  BMediaBody,
  BMediaAside,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormCheckbox,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import liqpay from '@/libs/liqpay'
import {
  currency,
} from '@/mixins/options'
import Cleave from 'vue-cleave-component'
import store from '@/store'
import i18n from '@/libs/i18n'
import useRoadMap from '@/layouts/components/road-map/useRoadMap'
import { formatDateTimeUTC } from '@/filters/dateTime'
import { $themeConfig } from '@themeConfig'
import { nextTick } from '@vue/composition-api'

export default {
  components: {
    BContainer,
    BButton,
    BButtonGroup,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    Cleave,
    BRow,
    BCol,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        amount: 20,
        currency: process.env.VUE_APP_CURRENCY,
        action: 'paydonate',
        description: [],
      },
      amountOptions: [200, 100, 60, 30, 20, 10],
      countryCode: 'UK',
      comment: '',
      currencySymbol: '',
      cleaveOptions: {
        amount: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      liqpayFormData: {
        data: null,
        signature: null,
      },
      donateTeamOptions: [
        {
          slug: 'donate_inspiration',
          description: 'donate_inspiration_desc',
        },
      ],
    }
  },
  computed: {
    amount: {
      get() {
        return this.form.amount
      },
      set(newVal) {
        this.form.amount = parseFloat(newVal)
      },
    },
  },
  watch: {
    'form.currency': {
      handler(newVal) {
        this.currencySymbol = this.getCurrencySymbol(newVal)
      },
    },
  },
  created() {
    this.countryCode = this.getCountryCode()
    this.form.currency = this.getCountryCurrencyCode()
  },
  methods: {
    getCountryCode() {
      return localStorage.getItem('countryCode') || 'UA'
    },
    getCountryCurrencyCode() {
      const countryCurrency = this.getCountryCurrency(this.countryCode)
      return countryCurrency.currency || this.form.currency
    },
    hideModal() {
      this.$refs['donates-modal'].hide()
    },
    getAmountButtonVariant(amountOption) {
      return this.form.amount === amountOption ? 'primary' : 'outline-primary'
    },
    getDonateTypeButtonVariant(buttonName) {
      return this.form.action === buttonName ? 'primary' : 'outline-primary'
    },
    async updateLiqpayFormData() {
      const params = { ...this.form }
      let donationPurposes = [...params.description]
      let prefix = i18n.t('donate.default_donation_purpose')
      if (params.description.length > 0) {
        prefix = i18n.t('donate.donate_for')
      }
      if (this.comment) {
        donationPurposes.push(this.comment)
      }
      if (params.action === 'subscribe') {
        params.subscribe_date_start = formatDateTimeUTC(new Date(), 'YYYY-MM-DD hh:mm:ss')
        params.subscribe_periodicity = 'month'
      }
      prefix = [this.appName, prefix].join('. ')
      donationPurposes = donationPurposes.join('; ')
      params.description = [prefix, donationPurposes].join(' ')
      params.order_id = this.getOrderId()
      this.liqpayFormData = await this.getFormData(params)
    },
    getOrderId() {
      const user = store.getters['account/user']
      const userId = user ? user.id : ''
      const orderId = [
        this.form.action,
        this.form.amount,
        this.form.currency,
        this.countryCode,
        userId,
      ]
      return orderId.join('_')
    },
    async submit() {
      await this.updateLiqpayFormData()
      await nextTick()
      this.$refs.liqpayForm.submit()
    },
  },

  setup() {
    const { appName } = $themeConfig.app
    const { getFormData, formUrl } = liqpay()
    const { getCountryCurrency, getCurrencySymbol, getCurrencies } = currency()
    const { getUpcomingFeatures } = useRoadMap()
    const currencyOptions = getCurrencies()
    const donateFeaturesOptions = getUpcomingFeatures()
    return {
      appName,
      currencyOptions,
      donateFeaturesOptions,
      formUrl,
      getFormData,
      getCurrencySymbol,
      getCountryCurrency,
    }
  },
}
</script>
<style scoped>
.input-group-append .input-group-text{
  padding: 0;
}
.input-group-append .input-group-text .custom-select{
  border: 0;
  height: auto;
}
.amount-options {
  display: flex;
  flex-wrap: wrap;
}
.amount-option {
  width: 33.3333333333%;
  padding: 2px;
  position: relative;
}
.input-group-prepend .input-group-text {
  min-width: 3em;
}
#amount{
  font-size: 36px;
  line-height: 52px;
  height: 52px;
}
.action .btn-primary>svg{
  color: #ea5455;
}
.custom-select {
  height: 100%!important;
}

</style>
