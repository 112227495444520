<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <!--      <b-img-->
      <!--        :src="currentLocale.img"-->
      <!--        height="14px"-->
      <!--        width="22px"-->
      <!--        :alt="currentLocale.locale"-->
      <!--      />-->
      <span class="text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in localesList"
      :key="localeObj.locale"
      @click="$i18n.locale = setLocale(localeObj.locale)"
    >
      <!--      <b-img-->
      <!--        :src="localeObj.img"-->
      <!--        height="14px"-->
      <!--        width="22px"-->
      <!--        :alt="localeObj.locale"-->
      <!--      />-->
      <span class="text-body">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { locales } from '@/mixins/options'
import { localize } from 'vee-validate'
import uk from 'vee-validate/dist/locale/uk.json'
import en from 'vee-validate/dist/locale/en.json'
import de from 'vee-validate/dist/locale/de.json'
import ru from 'vee-validate/dist/locale/ru.json'
import i18n from '@/libs/i18n'

localize({
  en,
  uk,
  de,
  ru,
})
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.localesList.find(l => l.locale === i18n.locale)
    },
  },
  methods: {
    setLocale(locale) {
      localStorage.setItem('locale', locale)
      localize(locale)
      return locale
    },
  },
  setup() {
    /* eslint-disable global-require */
    const { localesList } = locales()
    /* eslint-disable global-require */
    localize(i18n.locale)
    return {
      localesList,
    }
  },
}
</script>

<style>
.dropdown-menu{
  min-width: 1rem;
}
</style>
