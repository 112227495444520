<template>
  <div
    class="navbar-container d-flex content align-items-center"
  >
    <ul
      v-if="isNavMenuHidden"
      class="nav navbar-nav"
    >
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span class="brand-logo mr-1">
            <b-img
              height="32"
              :src="appLogoImage"
              alt="logo"
            />
          </span>
          <h2 class="brand-text mb-0 d-none d-sm-inline-block">
            {{ appName }}
          </h2>
        </b-link>
      </li>
    </ul>
    <!-- Nav Menu Toggler -->
    <ul
      v-if="!isNavMenuHidden"
      class="nav navbar-nav d-xl-none"
    >
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->
      <!--      <bookmarks />-->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <dark-toggler />
      <!--      <search-bar />-->
      <!--      <cart-dropdown />-->
      <notification-dropdown v-if="!isNavMenuHidden" />
      <user-dropdown />
      <user-switcher />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BImg,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import UserSwitcher from '@/layouts/components/app-navbar/components/UserSwitcher.vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    BImg,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    UserSwitcher,

  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
    isNavMenuHidden: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>
<style>

@media only screen and (max-width: 767px) {
  .vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu,
  .vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .dropdown-menu{
    margin-top:26px
  }
}
@media only screen and (max-width: 375px) {
  .dropdown-language .text-body{
    display: none;
  }
}
</style>
