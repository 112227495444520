import i18n from '@/libs/i18n'
import axios from '@/libs/axios'

export default function liqpay() {
  const formUrl = 'https://www.liqpay.ua/api/3/checkout'
  const getLanguage = () => i18n.locale
  const getResultUrl = () => window.location.origin + window.location.pathname

  const paramsPrepare = params => {
    const staticParams = {
      version: 3,
      language: getLanguage(),
      result_url: getResultUrl(),
    }
    return { ...staticParams, ...params }
  }

  const getFormData = async params => {
    const response = await axios.post('/liqpay', paramsPrepare(params)).catch(error => console.log(error))
    return response.data
  }

  return {
    getFormData,
    formUrl,
  }
}
