<template>
  <b-nav-item-dropdown
    ref="notification-dropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    @click.native.stop
  >
    <template #button-content>
      <feather-icon
        :badge="unreadNotificationsQty"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header align-middle">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto ">
          {{ $t('Notifications') }}
        </h4>
        <div
          v-if="notifications.length"
          class="mr-1"
        >

          <b-overlay
            :show="submittingReadAll"
            rounded
            opacity="0.6"
            spinner-medium
            spinner-type="grow"
            spinner-variant="secondary"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-secondary"
              class="btn-icon"
              size="sm"
              @click="readAllNotifications"
            >
              <feather-icon
                icon="CheckCircleIcon"
                size="20"
              />
            </b-button>
          </b-overlay>
        </div>
        <div>
          <b-overlay
            :show="submittingFetchNotifications"
            rounded
            opacity="0.6"
            spinner-medium
            spinner-type="grow"
            spinner-variant="secondary"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-secondary"
              class="btn-icon"
              size="sm"
              @click="fetchFilteredNotifications"
            >
              <feather-icon
                icon="RefreshCwIcon"
                size="20"
              />
            </b-button>
          </b-overlay>
        </div>
      </div>
      <div
        v-if="!notifications.length"
        class="notification-text text-center "
      >{{ $t('There are no new notifications') }}</div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-overlay
        :show="submittingFetchNotifications || submittingReadAll"
        rounded
        opacity="0.6"
        spinner-medium
        spinner-variant="primary"
      >
        <div
          v-for="notification in notifications"
          :key="notification.id"
        >
          <b-media>

            <template #aside>
              <a
                class="media-heading"
                @click="handleNotificationClickAction(notification)"
              >
                <b-avatar
                  size="32"
                  :variant="getNotificationVariant(notification.type)"
                >
                  <feather-icon :icon="getNotificationIcon(notification.type)" />
                </b-avatar>
              </a>
            </template>

            <a
              class="media-heading"
              @click="handleNotificationClickAction(notification)"
            >
              <span :class="notificationIsUnread(notification)?'font-weight-bolder':''">
                {{ $t(getNotificationTitle(notification.type)) }}
              </span>
              <div><small>{{ getEventDate(notification) }}</small></div>
              <div v-if="notification.data.service_request_id">
                <small>{{ $t('Request') }} # {{ notification.data.service_request_id }}</small>
              </div>
            </a>
          </b-media>
        </div>
      </b-overlay>

      <!-- System Notification Toggler -->
      <!--      <div class="media d-flex align-items-center">-->
      <!--        <h6 class="font-weight-bolder mr-auto mb-0">-->
      <!--          System Notifications-->
      <!--        </h6>-->
      <!--        <b-form-checkbox-->
      <!--          :checked="true"-->
      <!--          switch-->
      <!--        />-->
      <!--      </div>-->

      <!-- System Notifications -->
      <!--      <b-link-->
      <!--        v-for="notification in systemNotifications"-->
      <!--        :key="notification.subtitle"-->
      <!--      >-->
      <!--        <b-media>-->
      <!--          <template #aside>-->
      <!--            <b-avatar-->
      <!--              size="32"-->
      <!--              :variant="notification.type"-->
      <!--            >-->
      <!--              <feather-icon :icon="notification.icon" />-->
      <!--            </b-avatar>-->
      <!--          </template>-->
      <!--          <p class="media-heading">-->
      <!--            <span class="font-weight-bolder">-->
      <!--              {{ notification.title }}-->
      <!--            </span>-->
      <!--          </p>-->
      <!--          <small class="notification-text">{{ notification.subtitle }}</small>-->
      <!--        </b-media>-->
      <!--      </b-link>-->
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <!--    <li class="dropdown-menu-footer"><b-button-->
    <!--      v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
    <!--      variant="primary"-->
    <!--      block-->
    <!--    >Read all notifications</b-button>-->
    <!--    </li>-->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BMedia, BLink, BAvatar, BButton, BOverlay,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import {
  pushNotification, webNotification, notificationFiltersSortingAndPagination, manageNotificationActions,
} from '@/views/apps/notification/useNotification'
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter'

import { watch } from '@vue/composition-api'
import { formatDateTime } from '@/filters/dateTime'
import store from '@/store'

export default {
  components: {
    BNavItemDropdown,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDateTime,
  },

  computed: {
    notifications() {
      return store.getters['notification/notifications']
    },
    unreadNotificationsQty() {
      return store.getters['notification/unreadNotificationsQty']
    },
  },
  created() {
    GlobalEventEmitter.$on('newMessageReceived', response => {
      this.fetchFilteredNotifications()
      this.playSound()
    })
  },
  methods: {
    playSound() {
      return new Audio(require('@/assets/audio/notification-tone.wav')).play()
    },
    getEventDate(notification) {
      const date = notification.data.decline_date
          || notification.data.accept_date
          || notification.data.withdraw_date
          || notification.data.updated_date
          || notification.data.submit_date
      return date ? this.$options.filters.formatDateTime(date) : ''
    },
    handleNotificationClickAction(notification) {
      this.hideNotificationDropdown()
      return this.openServiceRequest(notification)
    },
    hideNotificationDropdown() {
      return this.$refs['notification-dropdown'].hide(true)
    },
    getNotificationIcon(type) {
      const obj = this.notificationOptions[type]
      return obj ? obj.icon : 'ToolIcon'
    },
    getNotificationVariant(type) {
      const obj = this.notificationOptions[type]
      return obj ? obj.variant : 'secondary'
    },
    getNotificationTitle(type) {
      const obj = this.notificationOptions[type]
      return obj ? obj.title : 'Title'
    },
  },
  setup() {
    const notificationOptions = {
      EventAcceptedNotification: {
        icon: 'CheckIcon',
        title: 'Proposal Accepted',
        variant: 'success',
      },
      EventCreatedNotification: {
        icon: 'ThumbsUpIcon',
        title: 'New Proposal',
        variant: 'primary',
      },
      EventDeclinedNotification: {
        icon: 'XCircleIcon',
        title: 'Proposal Declined',
        variant: 'danger',
      },
      EventWithdrawnNotification: {
        icon: 'RotateCcwIcon',
        title: 'Proposal Withdrawn',
        variant: 'warning',
      },
      EventUpdatedNotification: {
        icon: 'ThumbsUpIcon',
        title: 'Proposal Updated',
        variant: 'warning',
      },
      ServiceRequestCreatedNotification: {
        icon: 'PhoneCallIcon',
        title: 'New Request',
        variant: 'primary',
      },
    }
    const { getMessage } = pushNotification()
    getMessage()
    // const notifications = ref([])
    /* eslint-disable global-require */
    // const notifications = [
    //   {
    //     title: 'Congratulation Sam 🎉',
    //     avatar: require('@/assets/images/avatars/6-small.png'),
    //     subtitle: 'Won the monthly best seller badge',
    //     type: 'light-success',
    //   },
    //   {
    //     title: 'New message received',
    //     avatar: require('@/assets/images/avatars/9-small.png'),
    //     subtitle: 'You have 10 unread messages',
    //     type: 'light-info',
    //   },
    //   {
    //     title: 'Revised Order 👋',
    //     avatar: 'MD',
    //     subtitle: 'MD Inc. order updated',
    //     type: 'light-danger',
    //   },
    // ]
    // const notifications = [
    //   {
    //     title: 'Congratulation Sam 🎉',
    //     avatar: require('@/assets/images/avatars/6-small.png'),
    //     subtitle: 'Your request #45621 is successfully closed',
    //     type: 'light-success',
    //   },
    //   {
    //     title: 'New proposal received',
    //     avatar: require('@/assets/images/avatars/9-small.png'),
    //     subtitle: 'You have 10 unread proposals',
    //     type: 'light-info',
    //   },
    //   {
    //     title: 'New service request 👋',
    //     avatar: 'MD',
    //     subtitle: 'The air conditioner is not working',
    //     type: 'light-danger',
    //   },
    // ]
    /* eslint-disable global-require */

    // const systemNotifications = [
    //   {
    //     title: 'Server down',
    //     subtitle: 'USA Server is down due to hight CPU usage',
    //     type: 'light-danger',
    //     icon: 'XIcon',
    //   },
    //   {
    //     title: 'Sales report generated',
    //     subtitle: 'Last month sales report generated',
    //     type: 'light-success',
    //     icon: 'CheckIcon',
    //   },
    //   {
    //     title: 'High memory usage',
    //     subtitle: 'BLR Server using high memory',
    //     type: 'light-warning',
    //     icon: 'AlertTriangleIcon',
    //   },
    // ]
    const systemNotifications = [
      {
        title: 'New feature is available 🎉',
        subtitle: 'Try Service Road Map feature',
        type: 'light-success',
        icon: 'CheckIcon',
      },
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    const { openEvent, openServiceRequest } = manageNotificationActions()

    const {
      filters,
      filterOptions,
      sortBy,
      sortByOptions,
    } = notificationFiltersSortingAndPagination()
    const {
      totalNotifications,
      fetchNotifications,
      readAllNotifications,
      submittingReadAll,
      submittingFetchNotifications,
      notificationIsUnread,
    } = webNotification()

    // Wrapper Function for `fetchNotifications` which can be triggered initially and upon changes of filters
    const fetchFilteredNotifications = () => {
      const args = {
        page: filters.value.page,
        per_page: filters.value.perPage,
        order: filters.value.order,
      }

      fetchNotifications(args)
    }

    fetchFilteredNotifications()

    watch([filters, sortBy], () => {
      fetchFilteredNotifications()
    }, {
      deep: true,
    })
    return {
      // systemNotifications,
      perfectScrollbarSettings,
      openEvent,
      openServiceRequest,
      fetchFilteredNotifications,
      submittingFetchNotifications,
      readAllNotifications,
      notificationOptions,
      submittingReadAll,
      notificationIsUnread,
    }
  },
}
</script>

<style scoped>
.btn{
  min-height: auto;
}
</style>
