export default function useRoadMap() {
  const todoList = [
    {
      slug: 'notification_system',
      description: 'notification_system_desc',
      status: 'upcoming',
      actors: ['contractors', 'customers'],
    },
    {
      slug: 'rating_system',
      description: 'rating_system_desc',
      status: 'upcoming',
      actors: ['contractors', 'customers'],
    },
    {
      slug: 'discount_system',
      description: 'discount_system',
      status: 'upcoming',
      actors: ['contractors'],
    },
  ]

  const getUpcomingFeatures = () => todoList.filter(todo => todo.status === 'upcoming')

  return {
    getUpcomingFeatures,
  }
}
